import React from 'react';
import { Route } from 'react-router-dom';

import {
  SignIn as SignInView,
  SignUp as SignUpView,
  Settings as SettingsView,
  NotFound as NotFoundView,
  IntroProductView as ProductView,
  Planning as PlanningView,
  ResetPassword as ResetPasswordView
} from './views/Common';

/* Components is imported for the Mate-KR */
import {
  Dashboard as MateKrDashboard,
  Server as MateKrServer,
  DeviceSaerch as MateKrDeviceSearch,
  DeviceLog as MateKrDeviceLog,
  ConverterLogLevel as MateKrConverterLogLevel,
  ConverterBlackList as MateKrConverterBlackList,
  UserRecord as MateKrUserRecord,
  FirmwareRecord as MateKrFirmwareRecord,
  WaterStatistics as MateKrWaterStatistics,
  ElecStatistics as MateKrElecStatistics,
  NaviwatchUserSearch as MateKrNaviwatchUserSearch,
  NaviwatchUserDetail as MateKrNaviwatchUserDetail,
  FirmwareModels as MateKrFirmwareModels,
  FirmwareList as MateKrFirmwareList,
  FirmwareCreate as MateKrFirmwareCreate
} from './views/Mate-KR';

/* Components is imported for the Mate-US */
import {
  Dashboard as MateUsDashboard,
  Server as MateUsServer,
  User as MateUsUser,
  Device as MateUsDevices,
  Push as MateUsPush,
  DormancyUser as MateUsDormancyUser,
  DormancyHistory as MateUsDormancyHistory,
  Record as MateUsRecord,
  Statistics as MateUsStatistics,
  NaviwatchUserSearch as MateUsNaviwatchUserSearch,
  NaviwatchUserDetail as MateUsNaviwatchUserDetail,
  FirmwareModels as MateUsFirmwareModels,
  FirmwareList as MateUsFirmwareList,
  FirmwareCreate as MateUsFirmwareCreate
} from './views/Mate-US';

/* Components is imported for the SmartTok */
import {
  Dashboard as SmartTokDashboard,
  Server as SmartTokServer,
  Statistics as SmartTokStatistics,
  User as SmartTokUser,
  Device as SmartTokDevice,
  Dormancy as SmartTokDormancy,
  DormancyManage as SmartTokDormancyManage,
  Push as SmartTokPush,
  Record as SmartTokRecord,
  FirmwareProductView as SmartTokFirmware,
  FirmwareModels as SmartTokFirmwareModels,
  FirmwareList as SmartTokFirmwareList,
  NaviwatchUserSearch as SmartTokNaviwatchUserSearch,
  NaviwatchUserDetail as SmartTokNaviwatchUserDetail
} from './views/SmartTok';

/* Components is imported for the AirOne */
import {
  Dashboard as AironeDashboard,
  Server as AironeServer,
  Device as AironeDevice,
  DeviceHistory as AironeDeviceHistory,
  ConverterLogLevel as AironeConverterLogLevel,
  User as AironeUser,
  DormancyUser as AironeDormancyUser,
  DormancyHistory as AironeDormancyHistory,
  Push as AironePush,
  FirmwareModels as AironeFirmwareModels,
  FirmwareList as AironeFirmwareList,
  UserRecord as AironeUserRecord,
  FirmwareRecord as AironeFirmwareRecord,
  Statistics as AironeStatistics,
  NaviwatchUserSearch as AironeNaviwatchUserSearch,
  NaviwatchUserDetail as AironeNaviwatchUserDetail
} from './views/Airone';

/* Components is imported for the Navilink */
import {
  Dashboard as NavilinkDashboard,
  Server as NavilinkServer,
  Statistics as NavilinkStatistics,
  User as NavilinkUser,
  Device as NavilinkDevice,
  Dormancy as NavilinkDormancy,
  DormancyManage as NavilinkDormancyManage,
  Push as NavilinkPush,
  Record as NavilinkRecord,
  FirmwareProductView as NavilinkFirmware,
  FirmwareModels as NavilinkFirmwareModels,
  FirmwareList as NavilinkFirmwareList,
  NaviwatchUserSearch as NavilinkNaviwatchUserSearch,
  NaviwatchUserDetail as NavilinkNaviwatchUserDetail
} from './views/Navilink';

/* Components is imported for the SmartPlus */
import {
  Dashboard as SmartPlusDashboard,
  Server as SmartPlusServer,
  Statistics as SmartPlusStatistics,
  User as SmartPlusUser,
  Device as SmartPlusDevice,
  Dormancy as SmartPlusDormancy,
  DormancyManage as SmartPlusDormancyManage,
  Push as SmartPlusPush,
  Record as SmartPlusRecord,
  FirmwareProductView as SmartPlusFirmware,
  FirmwareModels as SmartPlusFirmwareModels,
  FirmwareList as SmartPlusFirmwareList,
  NaviwatchUserSearch as SmartPlusNaviwatchUserSearch,
  NaviwatchUserDetail as SmartPlusNaviwatchUserDetail
} from './views/SmartPlus';

/* Components is imported for the Smart */
import {
  Dashboard as SmartDashboard,
  User as SmartUser,
  Device as SmartDevice,
  Privacy as SmartPrivacy,
  Push as SmartPush,
  Announcement as SmartAnnouncement,
  Record as SmartRecord,
  NaviwatchUserSearch as SmartNaviwatchUserSearch,
  NaviwatchUserDetail as SmartNaviwatchUserDetail,
  Aisleep as UpSleepSensorInfo,
  AiSleepStatistics,
  MateDeviceLog as SmartMateKrDeviceLog
} from './views/Smart';

/* Components is imported for the HydroFurnace */
import {
  Dashboard as HydrofurnaceDashboard,
  FirmwareProductView as HydrofurnaceFirmware,
  FirmwareModels as HydrofurnaceFirmwareModels,
  FirmwareList as HydrofurnaceFirmwareList,
  NaviwatchUserSearch as HydrofurnaceNaviwatchUserSearch,
  NaviwatchUserDetail as HydrofurnaceNaviwatchUserDetail
} from './views/Hydrofurnace';

/* Components is imported for the NSCADA */
import {
  Dashboard as NscadaDashboard,
  FirmwareProductView as NscadaFirmware,
  FirmwareModels as NscadaFirmwareModels,
  FirmwareList as NscadaFirmwareList,
  NaviwatchUserSearch as NscadaNaviwatchUserSearch,
  NaviwatchUserDetail as NscadaNaviwatchUserDetail
} from './views/Nscada';

/* Components is imported for the NSCADA-KR */
import {
  Dashboard as NscadaKRDashboard,
  FirmwareProductView as NscadaKRFirmware,
  FirmwareModels as NscadaKRFirmwareModels,
  FirmwareList as NscadaKRFirmwareList,
  NaviwatchUserSearch as NscadaKRNaviwatchUserSearch,
  NaviwatchUserDetail as NscadaKRNaviwatchUserDetail
} from './views/Nscada-KR';

/* Components is imported for the SMARTTOK-CN */
import {
  Dashboard as SmartTokCNDashboard,
  FirmwareProductView as SmartTokCNFirmware,
  FirmwareModels as SmartTokCNFirmwareModels,
  FirmwareList as SmartTokCNFirmwareList,
  NaviwatchUserSearch as SmartTokCNNaviwatchUserSearch,
  NaviwatchUserDetail as SmartTokCNNaviwatchUserDetail
} from './views/SmartTok-CN';

const AppRoute = () => {
  return (
    <>
      {/*
        ************************
         MATE (KR) ROUTING
        ************************
         */}
      <Route path="/mate-kr/dashboard" exact component={MateKrDashboard} />
      <Route path="/mate-kr/server" exact component={MateKrServer} />
      <Route
        path="/mate-kr/statistics"
        exact
        component={MateKrWaterStatistics}
      />
      <Route
        path="/mate-kr/statistics/water"
        exact
        component={MateKrWaterStatistics}
      />
      <Route
        path="/mate-kr/statistics/elec"
        exact
        component={MateKrElecStatistics}
      />
      <Route path="/mate-kr/device" exact component={MateKrDeviceSearch} />
      <Route path="/mate-kr/device/log" exact component={MateKrDeviceLog} />
      <Route
        path="/mate-kr/device/search"
        exact
        component={MateKrDeviceSearch}
      />
      <Route
        path="/mate-kr/converter/log-level"
        exact
        component={MateKrConverterLogLevel}
      />
      <Route
        path="/mate-kr/converter/blacklist"
        exact
        component={MateKrConverterBlackList}
      />
      <Route path="/mate-kr/record" exact component={MateKrUserRecord} />
      <Route path="/mate-kr/record/user" exact component={MateKrUserRecord} />
      <Route
        path="/mate-kr/record/firmware"
        exact
        component={MateKrFirmwareRecord}
      />
      <Route path="/mate-kr/reports" exact component={PlanningView} />
      <Route path="/mate-kr/role" exact component={MateKrNaviwatchUserSearch} />
      <Route
        path="/mate-kr/role/:userEmail/:roleId"
        exact
        component={MateKrNaviwatchUserDetail}
      />
      <Route path="/mate-kr/setting" exact component={SettingsView} />
      <Route path="/mate-kr/firmware" exact component={MateKrFirmwareModels} />
      <Route
        path="/mate-kr/firmware/:modelCode"
        exact
        component={MateKrFirmwareList}
      />
      <Route
        path="/mate-kr/firmware/create/:modelName"
        exact
        component={MateKrFirmwareCreate}
      />
      {/*
        ************************
         MATE (US) ROUTING
        ************************
         */}
      <Route path="/mate-us/dashboard" exact component={MateUsDashboard} />
      <Route path="/mate-us/server" exact component={MateUsServer} />
      <Route path="/mate-us/statistics" exact component={MateUsStatistics} />
      <Route path="/mate-us/user" exact component={MateUsUser} />
      <Route path="/mate-us/device" exact component={MateUsDevices} />
      <Route path="/mate-us/push" exact component={MateUsPush} />
      <Route path="/mate-us/record" exact component={MateUsRecord} />
      <Route
        path="/mate-us/dormancyHistory"
        exact
        component={MateUsDormancyHistory}
      />
      <Route
        path="/mate-us/dormancyUser"
        exact
        component={MateUsDormancyUser}
      />
      <Route path="/mate-us/reports" exact component={PlanningView} />
      <Route path="/mate-us/role" exact component={MateUsNaviwatchUserSearch} />
      <Route
        path="/mate-us/role/:userEmail/:roleId"
        exact
        component={MateUsNaviwatchUserSearch}
      />
      <Route path="/mate-us/setting" exact component={SettingsView} />
      <Route path="/mate-us/firmware" exact component={MateUsFirmwareModels} />
      <Route
        path="/mate-us/firmware/:modelCode"
        exact
        component={MateUsFirmwareList}
      />
      <Route
        path="/mate-us/firmware/create/:modelName"
        exact
        component={MateUsFirmwareCreate}
      />
      {/*
        ************************
         SMARTTOK  ROUTING
        ************************
         */}
      <Route path="/smarttok/dashboard" exact component={SmartTokDashboard} />
      <Route path="/smarttok/server" exact component={SmartTokServer} />
      <Route path="/smarttok/statistics" exact component={SmartTokStatistics} />
      <Route path="/smarttok/user" exact component={SmartTokUser} />
      <Route path="/smarttok/device" exact component={SmartTokDevice} />
      <Route path="/smarttok/push" exact component={SmartTokPush} />
      <Route path="/smarttok/record" exact component={SmartTokRecord} />
      <Route
        path="/smarttok/dormancyHistory"
        exact
        component={SmartTokDormancy}
      />
      <Route
        path="/smarttok/dormancyUser"
        exact
        component={SmartTokDormancyManage}
      />
      <Route path="/smarttok/reports" exact component={PlanningView} />
      <Route
        path="/smarttok/role"
        exact
        component={SmartTokNaviwatchUserSearch}
      />
      <Route
        path="/smarttok/role/:userEmail/:roleId"
        exact
        component={SmartTokNaviwatchUserDetail}
      />
      <Route path="/smarttok/setting" exact component={SettingsView} />
      <Route path="/smarttok/firmware" exact component={SmartTokFirmware} />
      <Route
        path="/smarttok/firmware/:firmwareType"
        exact
        component={SmartTokFirmware}
      />
      <Route
        path="/smarttok/firmware/:firmwareType/:productname"
        exact
        component={SmartTokFirmwareModels}
      />
      <Route
        path="/smarttok/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={SmartTokFirmwareList}
      />
      {/*
        ************************
         AIRONE  ROUTING
        ************************
         */}
      <Route path="/airone/dashboard" exact component={AironeDashboard} />
      <Route path="/airone/server" exact component={AironeServer} />
      <Route path="/airone/statistics" exact component={AironeStatistics} />
      <Route path="/airone/user" exact component={AironeUser} />
      <Route path="/airone/device" exact component={AironeDevice} />
      <Route path="/airone/device/search" exact component={AironeDevice} />
      <Route
        path="/airone/device/history"
        exact
        component={AironeDeviceHistory}
      />
      <Route
        path="/airone/converter/log-level"
        exact
        component={AironeConverterLogLevel}
      />
      <Route path="/airone/push" exact component={AironePush} />
      <Route path="/airone/record" exact component={AironeUserRecord} />
      <Route path="/airone/record/user" exact component={AironeUserRecord} />
      <Route
        path="/airone/record/firmware"
        exact
        component={AironeFirmwareRecord}
      />
      <Route
        path="/airone/dormancyHistory"
        exact
        component={AironeDormancyHistory}
      />
      <Route path="/airone/dormancyUser" exact component={AironeDormancyUser} />
      <Route path="/airone/reports" exact component={PlanningView} />
      <Route path="/airone/role" exact component={AironeNaviwatchUserSearch} />
      <Route
        path="/airone/role/:userEmail/:roleId"
        exact
        component={AironeNaviwatchUserDetail}
      />
      <Route path="/airone/setting" exact component={SettingsView} />
      <Route path="/airone/firmware" exact component={AironeFirmwareModels} />
      <Route
        path="/airone/firmware/:modelCode"
        exact
        component={AironeFirmwareList}
      />
      {/*
        ************************
        NAVILINK  ROUTING
        ************************
      */}
      <Route path="/navilink/dashboard" exact component={NavilinkDashboard} />
      <Route
        path="/navilink/role"
        exact
        component={NavilinkNaviwatchUserSearch}
      />
      <Route
        path="/navilink/role/:userEmail/:roleId"
        exact
        component={NavilinkNaviwatchUserDetail}
      />
      <Route path="/navilink/setting" exact component={SettingsView} />
      {/*
        ************************
        NAVILINK PLANNING VIEW
        ************************
      */}
      <Route path="/navilink/user" exact component={PlanningView} />
      <Route path="/navilink/device" exact component={PlanningView} />
      <Route path="/navilink/statistics" exact component={PlanningView} />
      <Route path="/navilink/server" exact component={PlanningView} />
      <Route path="/navilink/dormancy" exact component={PlanningView} />
      <Route path="/navilink/dormancyManage" exact component={PlanningView} />
      <Route path="/navilink/push" exact component={PlanningView} />
      <Route path="/navilink/record" exact component={PlanningView} />
      <Route path="/navilink/reports" exact component={PlanningView} />
      <Route path="/navilink/firmware" exact component={NavilinkFirmware} />
      <Route
        path="/navilink/firmware/:firmwareType"
        exact
        component={NavilinkFirmware}
      />
      <Route
        path="/navilink/firmware/:firmwareType/:productname"
        exact
        component={NavilinkFirmwareModels}
      />
      <Route
        path="/navilink/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={NavilinkFirmwareList}
      />

      {/*
        ************************
        SMARTPLUS  ROUTING
        ************************
      */}
      <Route path="/smartplus/dashboard" exact component={SmartPlusDashboard} />
      <Route
        path="/smartplus/role"
        exact
        component={SmartPlusNaviwatchUserSearch}
      />
      <Route
        path="/smartplus/role/:userEmail/:roleId"
        exact
        component={SmartPlusNaviwatchUserDetail}
      />
      <Route path="/smartplus/setting" exact component={SettingsView} />
      {/*
        ************************
        SMARTPLUS PLANNING VIEW
        ************************
      */}
      <Route path="/smartplus/user" exact component={PlanningView} />
      <Route path="/smartplus/device" exact component={PlanningView} />
      <Route path="/smartplus/statics" exact component={PlanningView} />
      <Route path="/smartplus/server" exact component={PlanningView} />
      <Route path="/smartplus/dormancy" exact component={PlanningView} />
      <Route path="/smartplus/dormancyManage" exact component={PlanningView} />
      <Route path="/smartplus/push" exact component={PlanningView} />
      <Route path="/smartplus/record" exact component={PlanningView} />
      <Route path="/smartplus/reports" exact component={PlanningView} />
      <Route path="/smartplus/firmware" exact component={SmartPlusFirmware} />
      <Route
        path="/smartplus/firmware/:firmwareType"
        exact
        component={SmartPlusFirmware}
      />
      <Route
        path="/smartplus/firmware/:firmwareType/:productname"
        exact
        component={SmartPlusFirmwareModels}
      />
      <Route
        path="/smartplus/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={SmartPlusFirmwareList}
      />
      {/*
        ************************
        SMART ROUTING
        ************************
         */}
      <Route path="/smart/dashboard" exact component={SmartDashboard} />
      <Route path="/smart/user" exact component={SmartUser} />
      <Route path="/smart/privacy" exact component={SmartPrivacy} />
      <Route path="/smart/device/history" exact component={SmartDevice} />
      {/* <Route path="/smart/push" exact component={SmartPush} /> */}
      <Route path="/smart/push" exact component={SmartAnnouncement} />
      <Route path="/smart/record" exact component={SmartRecord} />
      <Route path="/smart/reports" exact component={PlanningView} />
      <Route path="/smart/role" exact component={SmartNaviwatchUserSearch} />
      <Route path="/smart/upsleep" exact component={UpSleepSensorInfo} />
      <Route
        path="/smart/upsleep/statistics"
        exact
        component={AiSleepStatistics}
      />
      <Route
        path="/smart/device/log/mate-kr"
        exact
        component={SmartMateKrDeviceLog}
      />
      <Route
        path="/smart/role/:userEmail/:roleId"
        exact
        component={SmartNaviwatchUserDetail}
      />
      <Route path="/smart/setting" exact component={SettingsView} />
      {/*
        ************************
          HYDROFURNACE  ROUTING
        ************************
      */}
      <Route
        path="/hydrofurnace/dashboard"
        exact
        component={HydrofurnaceDashboard}
      />
      <Route
        path="/hydrofurnace/role"
        exact
        component={HydrofurnaceNaviwatchUserSearch}
      />
      <Route
        path="/hydrofurnace/role/:userEmail/:roleId"
        exact
        component={HydrofurnaceNaviwatchUserDetail}
      />
      <Route path="/hydrofurnace/setting" exact component={SettingsView} />
      <Route
        path="/hydrofurnace/firmware"
        exact
        component={HydrofurnaceFirmware}
      />
      <Route
        path="/hydrofurnace/firmware/:firmwareType"
        exact
        component={HydrofurnaceFirmware}
      />
      <Route
        path="/hydrofurnace/firmware/:firmwareType/:productname"
        exact
        component={HydrofurnaceFirmwareModels}
      />
      <Route
        path="/hydrofurnace/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={HydrofurnaceFirmwareList}
      />
      {/*
        ************************
          NSCADA  ROUTING
        ************************
      */}
      <Route path="/nscada/dashboard" exact component={NscadaDashboard} />
      <Route path="/nscada/role" exact component={NscadaNaviwatchUserSearch} />
      <Route
        path="/nscada/role/:userEmail/:roleId"
        exact
        component={NscadaNaviwatchUserDetail}
      />
      <Route path="/nscada/setting" exact component={SettingsView} />
      <Route path="/nscada/firmware" exact component={NscadaFirmware} />
      <Route
        path="/nscada/firmware/:firmwareType"
        exact
        component={NscadaFirmware}
      />
      <Route
        path="/nscada/firmware/:firmwareType/:productname"
        exact
        component={NscadaFirmwareModels}
      />
      <Route
        path="/nscada/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={NscadaFirmwareList}
      />
      {/*
        ************************
          NSCADA-KR  ROUTING
        ************************
      */}
      <Route path="/nscada-kr/dashboard" exact component={NscadaKRDashboard} />
      <Route
        path="/nscada-kr/role"
        exact
        component={NscadaKRNaviwatchUserSearch}
      />
      <Route
        path="/nscada-kr/role/:userEmail/:roleId"
        exact
        component={NscadaKRNaviwatchUserDetail}
      />
      <Route path="/nscada-kr/setting" exact component={SettingsView} />
      <Route path="/nscada-kr/firmware" exact component={NscadaKRFirmware} />
      <Route
        path="/nscada-kr/firmware/:firmwareType"
        exact
        component={NscadaKRFirmware}
      />
      <Route
        path="/nscada-kr/firmware/:firmwareType/:productname"
        exact
        component={NscadaKRFirmwareModels}
      />
      <Route
        path="/nscada-kr/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={NscadaKRFirmwareList}
      />
      {/*
        ************************
          SMARTTOK-CN  ROUTING
        ************************
      */}
      <Route
        path="/smarttok-cn/dashboard"
        exact
        component={SmartTokCNDashboard}
      />
      <Route
        path="/smarttok-cn/role"
        exact
        component={SmartTokCNNaviwatchUserSearch}
      />
      <Route
        path="/smarttok-cn/role/:userEmail/:roleId"
        exact
        component={SmartTokCNNaviwatchUserDetail}
      />
      <Route path="/smarttok-cn/setting" exact component={SettingsView} />
      <Route
        path="/smarttok-cn/firmware"
        exact
        component={SmartTokCNFirmware}
      />
      <Route
        path="/smarttok-cn/firmware/:firmwareType"
        exact
        component={SmartTokCNFirmware}
      />
      <Route
        path="/smarttok-cn/firmware/:firmwareType/:productname"
        exact
        component={SmartTokCNFirmwareModels}
      />
      <Route
        path="/smarttok-cn/firmware/:firmwareType/:productname/:swCodeMasking/:modelname"
        exact
        component={SmartTokCNFirmwareList}
      />
      {/*
        ************************
         DEFAULT  ROUTING
        ************************
         */}
      <Route path="/setting" exact component={SettingsView} />
      <Route path="/sign-up" exact component={SignUpView} />
      <Route path="/reset-password" exact component={ResetPasswordView} />
      <Route path="/sign-in" exact component={SignInView} />
      <Route path="/not-found" exact component={NotFoundView} />
      <Route path="/Planning/:type" exact component={PlanningView} />
      <Route path="/IntroProduct" exact component={ProductView} />
      {/* <Redirect to="/not-found" /> */}
    </>
  );
};

export default AppRoute;
