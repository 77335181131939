import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';

import PropTypes from 'prop-types';

import UpSleepStatisticsToolbar from './UpSleepStatisticsToolbar';
import useAsync from '../../../../asyncNet';
import { getSleepStatistics as fetchSleepStatistics } from '../../APIs/smartApis';
import UpSleepStatisticsChart from './UpSleepStatisticsChart';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const UpSleepStatistics = props => {
  const classes = useStyles();

  const [today, setToday] = useState('');
  const [isSleepStatistics, setIsSleepStatistics] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [sleepStatisticsQuery, setSleepStatisticsQuery] = useState({});
  const [statisticsList, setSleepStatisticsList] = useState([]);
  async function fetchGetSleepStatistics() {
    return await fetchSleepStatistics(sleepStatisticsQuery);
  }
  const [getStatistics, tryGetSleepStatistics] = useAsync(
    fetchGetSleepStatistics,
    [],
    true
  );

  useEffect(() => {
    const locale = new Date().toLocaleString('en-US', {
      timeZone: 'UTC'
    });
    const year = new Date(locale).getFullYear();
    const month = new Date(locale).getMonth();
    const day = new Date(locale).getDate();
    setToday(`${year}-${month + 1}-${day}`);
  }, []);

  useEffect(() => {
    const locale = new Date().toLocaleString('en-US', {
      timeZone: 'UTC'
    });
    const year = new Date(locale).getFullYear();
    const month = new Date(locale).getMonth();
    const day = new Date(locale).getDate();
    const hour = new Date(locale).getHours();
    let fromTimestamp = new Date(year, month, 1).getTime() / 1000 + 32400;
    let toTimestamp = new Date(year, month, day, hour).getTime() / 1000 + 32400;
    setLoadingAnimation(true);
    setIsSleepStatistics(true);
    setSleepStatisticsQuery({
      ...sleepStatisticsQuery,
      from: fromTimestamp,
      to: toTimestamp
    });
    setIsSleepStatistics(true);
  }, []);

  useEffect(() => {
    if (isSleepStatistics && Object.keys(sleepStatisticsQuery).length > 0) {
      console.log('fetch');
      tryGetSleepStatistics();
    }
  }, [isSleepStatistics, sleepStatisticsQuery]);

  const getSleepStatistics = async values => {
    try {
      setLoadingAnimation(true);
      let to = values.to.split('-');
      const locale = new Date().toLocaleString('en-US', {
        timeZone: 'UTC'
      });
      let hour = 0;
      if (today == values.to) {
        hour = new Date(values.to).getHours();
      } else {
        hour = 24;
      }
      let fromTimestamp = new Date(values.from).getTime() / 1000 + 32400;
      let toTimestamp =
        new Date(
          Number(to[0]),
          Number(to[1]) - 1,
          Number(to[2]),
          hour
        ).getTime() /
          1000 +
        32399;
      setIsSleepStatistics(true);
      setSleepStatisticsQuery({
        ...sleepStatisticsQuery,
        from: fromTimestamp,
        to: toTimestamp
      });
    } catch (e) {
      console.log(e);
    }
  };

  const {
    loading: sleepStatisticsLoading,
    data: sleepStatisticsList
  } = getStatistics;

  useEffect(() => {
    if (!sleepStatisticsLoading && isSleepStatistics) {
      setSleepStatisticsList([]);
      if (
        sleepStatisticsList &&
        sleepStatisticsList?.data?.data?.length != statisticsList.length
      ) {
        setSleepStatisticsList(sleepStatisticsList.data.data);
        setLoadingAnimation(false);
        setIsSleepStatistics(false);
      }
    }
  }, [
    sleepStatisticsList,
    sleepStatisticsList?.data?.data?.length,
    sleepStatisticsLoading,
    isSleepStatistics
  ]);

  return (
    <div>
      <Grid>
        <Backdrop className={classes.backdrop} open={loadingAnimation}>
          <CircularProgress color={'primary'} />
        </Backdrop>
        <UpSleepStatisticsToolbar getSleepStatistics={getSleepStatistics} />
        <UpSleepStatisticsChart data={statisticsList} />
      </Grid>
    </div>
  );
};

UpSleepStatistics.propTypes = {
  className: PropTypes.string,
  resultSet: PropTypes.object
};

export default UpSleepStatistics;
